import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, message} from 'antd';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {ImportExcel} from '../components';
import {useParams} from 'react-router-dom'

import {
    DownloadOutlined,
    LeftOutlined,
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    MinusOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const AttendeeGroupList = (props) => {
    let params = useParams()
    let id = params.id;
    const {Search} = Input;
    let [excelData, setExcelData] = useState([]);
    let [data, setData] = useState([]);
    let [group, setGroup] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false);
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let path = props.location.pathname.split('/')[1];
    let [module] = modules.filter(el => el._id === path);
    let [isSmall,] = useGlobal('isSmall')
    let query = []
    query["deleted"] = false;
    query["allow_donation"] = false;
    query["blood_donation"] = false;
    query["platelet_donation"] = false;
    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let profileSettings;
    let customization;
    let settings = useGlobal("settings")[0]
    if (settings) {
        settings.forEach(element => {
            if (element._id === "profileSettings") {
                profileSettings = element
            }
            if (element._id === "customization") {
                customization = element
            }
        });
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'name';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name, email, phone";
        }

        let restData = await api.get(`/rest/attendees?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    if (!item.hasOwnProperty('groups')) {
                        item.groups = []
                    }
                    if (item.isLogin)
                        item.isLogin = <div className="true-circle"></div>;
                    else
                        item.isLogin = <div className="false-circle"></div>;

                    if (item.groups.find(e => e._id === id))
                        item.isGroup = true;
                    else
                        item.isGroup = false;

                    item.key = key;
                    return item;
                })
            }
        });

        await api.get("/rest/attendeeGroups/" + id).then(({data: {result, result_message}}) => {
            if (result)
                setGroup(result);
        });
        setData(restData);
    }
    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let attendeeUpdate = async (e) => {
        e.groups = e.groups.map(g => g._id);
        e.sendSms = false

        if (e.isGroup)
            e.groups = e.groups.filter(g => g !== id)
        else
            e.groups.push(id)

        delete e.isGroup;
        delete e.active;
        delete e.key;
        delete e._score;
        api.put("/rest/attendees/" + e._id, e).then(({data: {result, result_message}}) => {
            if (result_message.type === 'success') {
                message.success(languagesCms ? languagesCms.SUCCESS_EDIT_RECORD : '', 2);
                get();
            } else
                message.error(result_message.message, 2);
        })
    }

    let columns = [
        {
      title: languagesCms ?  languagesCms.EMAIL : '',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email - b.email,
            sortDirections: ['descend', 'ascend']
        }, {
      title:languagesCms ?  languagesCms.PHONE : '',
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone - b.phone,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms ? languagesCms.NAME : '',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms ?  languagesCms.LASTNAME : '',
            dataIndex: 'lastname',
            key: 'lastname',
            sorter: (a, b) => a.lastname - b.lastname,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms ? languagesCms.GROUP : '',
            dataIndex: 'groups',
            key: 'groups',
            sorter: (a, b) => a.groups - b.groups,
            sortDirections: ['descend', 'ascend'],
            render: (groups) => groups.length > 0 && groups.map((e, i) => <div key={i} style={{marginBottom: 5}}>{e.name}</div>)
        },
        {
            title: 'Action',
            dataIndex: 'groups',
            key: 'action',
            render: (groups, record) => {
                return (
                    <Space size="small">
                        <Button icon={record.isGroup ? <MinusOutlined/> : <PlusOutlined/>}
                                onClick={() => attendeeUpdate(record)}>
                        </Button>
                    </Space>
                )
            }
        }
    ];

    let downloadExcel = async () => {
        var option = {};
        let dataTable = [];
        let query = []
        query["groups"] = id
        if (customization.isSmarteventView) {
            query["deleted"] = false;
        }

        let excelData = await api.get(`/rest/attendees?${qs.stringify({
            page: 1,
            pageSize: 10000,
            query
        })}`, {}).then(({data}) => {
            return data.result.rows;
        });

        if (excelData) {
            for (let i in excelData) {
                let obj = {
                    'name': excelData[i].name,
                    'lastname': excelData[i].lastname,
                    'phone': excelData[i].phone,
                    'email': excelData[i].email,
                    'city': excelData[i].location,
                    'birthday': excelData[i].birthday,
                    'position': excelData[i].position,
                    'deleted': excelData[i].deleted ? 'Silindi' : 'Aktif',
                    'isLogin': excelData[i].isLogin ? 'Giriş Yaptı' : 'Giriş Yapmadı',
                    'isLoginDate': excelData[i].isLoginTime ? moment(excelData[i].isLoginTime).format("DD-MM-YYYY") : '',
                    'isLoginTime': excelData[i].isLoginTime ? moment(excelData[i].isLoginTime).format("HH:mm") : '',
                }

                //Ek alanlar
                if (profileSettings.showCompany) {
                    obj.company = excelData[i].company;
                }
                if (profileSettings.showPosition) {
                    obj.position = excelData[i].position;
                }
                if (profileSettings.showDepartment) {
                    obj.department = excelData[i].department;
                }
                if (profileSettings.showRegisterNo) {
                    obj.registerNo = excelData[i].registerNo
                }
                if (profileSettings.showJobStartDate) {
                    obj.jobStartDate = excelData[i].jobStartDate ? moment(excelData[i].jobStartDate).format("MM-DD-YYYY") : ''
                }
                dataTable.push(obj);
            }
        }

        let sheetAreas = ['name', 'lastname', 'phone', 'email', 'city', 'birthday', 'deleted', 'isLogin', 'isLoginDate',
            'isLoginTime',];

        //smartevent için
        if (customization.isSmarteventView) {
            sheetAreas = ['name', 'lastname', 'phone', 'email', 'isLogin', 'isLoginDate',
                'isLoginTime'];
        }

        //Ek alanlar
        if (profileSettings.showCompany) {
            sheetAreas.push("company");
        }
        if (profileSettings.showPosition) {
            sheetAreas.push("position");
        }
        if (profileSettings.showDepartment) {
            sheetAreas.push("department");
        }
        if (profileSettings.showRegisterNo) {
            sheetAreas.push("registerNo");
        }
        if (profileSettings.showJobStartDate) {
            sheetAreas.push("jobStartDate");
        }

        option.fileName = group.name
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: sheetAreas,
                sheetHeader: sheetAreas,

            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    let downloadExcel2 = async () => {
        var option = {};
        let dataTable = [];
        let query2 = []
        query2["groups"] = id

        let excelData = await api.get(`/rest/attendees?${qs.stringify({
            page: 1,
            pageSize: 10000,
            query2
        })}`, {}).then(({data}) => {
            return data.result.rows;
        });

        if (excelData) {
            for (let i in excelData) {

                let obj = {
                    'identityNumber': excelData[i].identityNumber
                }
                dataTable.push(obj);
            }
        }

        option.fileName = group.name
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: ['identityNumber'],
                sheetHeader: ['identityNumber'],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    let uploadExcel = async () => {
        setLoading(true)
        if (excelData) {
            let already = 0;
            let count = 0;


            await Promise.all(excelData.map(async (item) => {

                query["phone"] = item.identityNumber;

                await api.get(`/rest/attendees?${qs.stringify({
                    page: 1,
                    pageSize: 10000,
                    query
                })}`, {}).then(({data}) => {
                    console.log("data result     ",data.result)
                    if (data.result.rows[0].groups.find(e => e._id === id))
                        data.result.rows[0].isGroup = true;
                    else
                        data.result.rows[0].isGroup = false;

                    if (!data.result.rows[0].isGroup) {
                        attendeeUpdate(data.result.rows[0]);
                        count++;
                    } else {
                        already++;
                    }

                });
            }));

            if (count || already) {
                setLoading(false)
                delete query["identityNumber"];
                get();
                message.success("Excel Yüklendi. " + count + " kişi eklendi, " + already + " kişi zaten vardı");
            }
        }
    }

    useEffect(() => {
        uploadExcel();
    }, [excelData]);

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""} </h1>
                    <p><font face="tahoma" size="3">{group ? group.name : ""} </font></p>
                </div>
                <div className="list-buttons">
                    {!isSmall && <div style={{marginRight: "5px"}}>
                        <ImportExcel setExcelData={setExcelData}/>
                    </div>}
                    <Button size="large" onClick={downloadExcel2} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{isSmall ? '' : 'Export Kullanıcı'}</Button>

                    <Button size="large" onClick={downloadExcel} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{isSmall ? '' : 'Export Excel'}</Button>
                    <Link to="/attendeeGroups">
                        <Button type="light" icon={<LeftOutlined/>} size="large">{isSmall ? '' : 'GERİ..'}</Button>
                    </Link>

                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={languagesCms ? languagesCms.SEARCH_NAME_LASTNAME_PHONE : ''} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}/>
            </div>

        </div>
    );
};


export default AttendeeGroupList;
