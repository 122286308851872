import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  DatePicker,
  Card,
  message,
  Alert,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {ChromePicker} from "react-color";
import rgbHex from "rgb-hex";
import {
  FileInputSingle,
  GroupPicker,
  AttendeePicker,
  SendNotificationInput,
  ModulePicker,
  ItemByNotifModulePicker,
  LangPicker,
  PinNotificationInput,
  SendNowNotificationInput,
} from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

const PopupDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let [moduleChange, setModuleChange] = useState(false);

  let id = params.id !== 'add' ? params.id : false;
  const { TextArea } = Input;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCode] = useGlobal('langCode');
  let [langCms] = useGlobal('langCms');

  let newRecord = {
    lang: langCode ? langCode : langCms[0].code,
    title: '',
    content: '',
    active: true,
    isDirect: false,
    counter:false,
    date: new Date(),
    groups: [],
    buttonTextColor:"#000000",
    timeTextColor:"#000000",
    timeTitleTextColor:"#000000",
    titleTextColor:"#000000",
    buttonBackgroundColor:"#ffffff",
    counterBackgroundColor:"#ffffff",
    contentTextColor:"#000000",
    contentBackgroundColor:"#ffffff",
    displayTimeInterval:24
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/popup/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.lang === null) errors.lang = languagesCms.ENFORCED;

    if (data.title === null || data.title.length === 0)
      errors.title = languagesCms.ENFORCED;

    if (data.content === null || data.content.length === 0)
      errors.content = languagesCms.ENFORCED;


    if (data.date === null) errors.date = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/popup/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/popup');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/popup', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/popup');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };
  console.log("data.type   ",data.type)
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/popup">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker
                    langCms={langCms}
                    langCode={langCode}
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="lang"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.GROUP}
                  help={errors.groups}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={"Durum"}
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SENDED_TIME}
                  required
                  help={errors.date}
                  validateStatus={errors.date ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.date)}
                    onChange={(v) => setData({ ...data, date: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={"Hemen Gönder"}                  
                >
                  <Switch
                    checked={data.sendNow ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, sendNow: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Görüntülenme Sıklığı'}
                  help={errors.frequency}
                  validateStatus={errors.frequency ? 'error' : 'success'}
                >
                    <Input
                    name="frequency"
                    type="number"
                    value={data.frequency}
                    onChange={(e) =>
                      setData({ ...data, frequency: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Görüntülenme Saat Aralığı'}
                  help={errors.displayTimeInterval}
                  validateStatus={errors.displayTimeInterval ? 'error' : 'success'}
                >
                    <Input
                    name="displayTimeInterval"
                    type="number"
                    value={data.displayTimeInterval}
                    onChange={(e) =>
                      setData({ ...data, displayTimeInterval: e.target.value })
                    }
                  />
                 <Alert message={"Saat cinsinden girilmedilir! Default olarak 24 saat kabul edilir."} banner />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={"Sayaç Ekle"}
                  help={errors.counter}
                  validateStatus={errors.counter ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.counter ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, counter: v })}
                  />
                </Form.Item>
              </Col>
              </Row>

              {data.counter &&
            <Row direction="row">

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={"Sayaç Zamanı"}
                  required
                  help={errors.counterTime}
                  validateStatus={errors.counterTime ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.counterTime)}
                    onChange={(v) => setData({ ...data, counterTime: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="Sayaç Arkaplan Rengi" help={errors.counterBackgroundColor}
                         validateStatus={errors.counterBackgroundColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.counterBackgroundColor}
                      onChange={x => setData({
                          ...data,
                          counterBackgroundColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="Sayaç Yazı Rengi" help={errors.timeTextColor}
                         validateStatus={errors.timeTextColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.timeTextColor}
                      onChange={x => setData({
                          ...data,
                          timeTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="Sayaç Başlık Rengi" help={errors.timeTitleTextColor}
                         validateStatus={errors.timeTitleTextColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.timeTitleTextColor}
                      onChange={x => setData({
                          ...data,
                          timeTitleTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
          </Row>
          

              }


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.title}
                  validateStatus={errors.title ? 'error' : 'success'}
                >
                  <Input
                    name="title"
                    value={data.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="Başlık Yazı Rengi" help={errors.titleTextColor}
                         validateStatus={errors.titleTextColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.titleTextColor}
                      onChange={x => setData({
                          ...data,
                          titleTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.CONTENTS}
                  required
                  help={errors.content}
                  validateStatus={errors.content ? 'error' : 'success'}
                >
                  <TextArea
                    name="content"
                    value={data.content}
                    onChange={(e) =>
                      setData({ ...data, content: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="İçerik Yazı Rengi" help={errors.contentTextColor}
                         validateStatus={errors.contentTextColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.contentTextColor}
                      onChange={x => setData({
                          ...data,
                          contentTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="İçerik Arkaplan Rengi" help={errors.contentBackgroundColor}
                         validateStatus={errors.contentBackgroundColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.contentBackgroundColor}
                      onChange={x => setData({
                          ...data,
                          contentBackgroundColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
            </Row>
            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.REDIRECT_APP} help={errors.isDirect}
                                           validateStatus={errors.isDirect ? 'error' : 'success'}>
                                    <Switch checked={data.isDirect ? true : false} checkedChildren={languagesCms.ACTIVE}
                                            unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({...data, isDirect: v})}/>
                                </Form.Item>
                            </Col>

                            {data.isDirect === false &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Link Url" help={errors.linkUrl}
                                               validateStatus={errors.linkUrl ? 'error' : 'success'}>
                                        <Input name="linkUrl" value={data.linkUrl}
                                               onChange={e => setData({...data, linkUrl: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }
 {data.isDirect === true &&
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.OPEN_MODULE}
                  // required
                  // help={errors.type}
                  // validateStatus={errors.type ? 'error' : 'success'}
                >
                  <ModulePicker
                    isNotificationPage={true}
                    languagesCms={languagesCms}
                    langCode={langCode}
                    langCms={langCms}
                    record={data} 
                    setRecord={setData} 
                    moduleChange={moduleChange} 
                    setModuleChange={setModuleChange}
                    name="type"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.SHARING} required help={errors.item_id} validateStatus={errors.type ? 'error' : 'success'}>
                  <ItemByNotifModulePicker languagesCms={languagesCms} record={data} setRecord={setData} name="item_id" moduleChange={moduleChange}
                    disabled={data.type === "not_modul" || data.type === "notifications" || !data.type ? true : false} />
                </Form.Item>
              </Col>
            </Row>
            }

            <Row direction="row">
              <Col span={24}>
                <Form.Item
                  label={languagesCms.MEDIA}
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="media"
                  />
                  <Alert
                    message={languagesCms.NOTIFICATION_MEDIA_SIZE}
                    banner
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Buton text" >
                                            <Input name="buttonText" value={data.buttonText}
                                                   onChange={e => setData({...data, buttonText: e.target.value})}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Buton text Color" help={errors.buttonTextColor}
                                                   validateStatus={errors.buttonTextColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.buttonTextColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    buttonTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            /> </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Buton Background Color" help={errors.buttonBackgroundColor}
                                                   validateStatus={errors.buttonBackgroundColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.buttonBackgroundColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    buttonBackgroundColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            />
                                        </Form.Item>
                                    </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default PopupDetail;
