import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, message } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { ImportExcel } from '../components';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  DownloadOutlined,
  FileMarkdownOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';

const InvitationCode = (props) => {
  const { Search } = Input;
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');

  let [data, setData] = useState([]);
  let [excelData, setExcelData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  let [current, setCurrent] = useState(1);

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };


  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'code';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }  
    let query = [];


    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }

    let restData = await api
      .get(`/rest/invitationCodes?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          console.log("data.result    ",data.result)

          setLoading(false);
          setCurrent(data.result.page);
          setTotalCount(data.result.total);

          return data.result.rows.map((item, key) => {
            if (item.active)
              item.active = <div className="true-circle"></div>;
            else item.active = <div className="false-circle"></div>;


            return item;
          });
        }
      });
    setData(restData);
  };

  console.log("DATA     ",data)
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/invitationCodes/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/invitationCodes/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };
  let uploadExcel = async () => {
    let code;

    let insertCount = 0;
    console.log("excelData     ",excelData)
    if (excelData) {
      for (let i in excelData) {
        code = {
          code: String(excelData[i].code).trim(),
          isOne:excelData[i].isOne
        };


          await api
            .post('/rest/invitationCodes', code)
            .then(({ data: { result, result_message } }) => {});
          insertCount++;
        }

        if (insertCount) {
        message.error(
          'Excel Yüklendi. ' +
            insertCount +
            ' Kayıt Eklendi.',
          2,
        );
      }
    }
  };
  useEffect(() => {
    uploadExcel();
  }, [excelData]);

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let getReport = async () => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(`/rest/reports/invitationCodes?lang=${lang}`);
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `invitationCodes.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let columns = [
    {
      title: "Kod ",
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => turkishSort(a.code, b.code),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Kullanıcı",
      dataIndex: 'codeOwner',
      key: 'codeOwner',
      sorter: (a, b) => a.codeOwner - b.codeOwner,
      sortDirections: ['descend', 'ascend'],
      render: (codeOwner) =>
        codeOwner && 
          <div  style={{ marginBottom: 5 }}>
            {codeOwner.name+" "+ codeOwner.lastname+" "+ codeOwner.phone}
          </div>
          
    },
    {
      title: "Kullanım Durumu",
      width: '50px',
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: languagesCms.GROUP,
    //   dataIndex: 'groups',
    //   key: 'groups',
    //   sorter: (a, b) => a.groups - b.groups,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (groups) =>
    //     groups.length > 0 &&
    //     groups.map((e, i) => (
    //       <div key={i} style={{ marginBottom: 5 }}>
    //         {e.name}
    //       </div>
    //     )),
    // },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/invitationCode/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {!isSmall && languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {!isSmall && languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons">
        <Button
            type="primary"
            icon={<FileMarkdownOutlined />}
            size="large"
            style={{ marginRight: '5px' }}
            onClick={() => getReport()}
          >
            {isSmall ? '' : "Kodlar"}
          </Button>
        {!isSmall && <ImportExcel setExcelData={setExcelData} />}

          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to="/invitationCode/add" style={{ marginRight: '5px' }}>
            <Button type="light" icon={<PlusOutlined />} size={'large'}>
              {!isSmall && languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={"Ara"}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
            current: current,
            onChange: (current) => {
              setCurrent(current);
            },
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default InvitationCode;
