import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [speaker, setGroup] = useState([]);
  let [attendee, setAttendee] = useState([]);

  let _params = { sort: 'order', page: 1, pageSize: 100 };
  let languagesCms = props.languagesCms;
  useMemo(() => {
    api.get(`/rest/speakers?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        let dbGroups = data.result.rows.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setAttendee(dbGroups);
      }
    });
  }, []);

  let mentionedUsers = (v) => {
    let attendees = v.map((x) => {
      let mentionedUser = attendee.find((e) => e._id === x);
      console.log("vv     ",mentionedUser)
      return {
        _id: mentionedUser._id,
        name: mentionedUser.name,
        lastname: mentionedUser.lastname,
        avatar:    {
        "_id": "yASVcVEwiz0fV8GzCqQAZGaC.png",
        "url": mentionedUser.imageurl,
        "thumb": mentionedUser.imageurl,
        "type": "image",
        "mimeType": "image/png"
        },
        company:mentionedUser.company || "",
        position: mentionedUser.position || "",
        department: mentionedUser.department || "",
        isSelected: true,
      };
    });
    setRecord({ ...record, [name]: attendees });
  };

  return (
    <Select
      defaultValue={record[name] ? record[name].map((e) => e._id) : []}
      mode="multiple"
      placeholder={languagesCms.SELECT_ATTENDEE}
      showSearch={false}
      onChange={mentionedUsers}
      // filterOption={(input, option) =>
      //   option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
      //     0 ||
      //   option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
    >
      {attendee &&
        attendee.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name} {b.lastname}
          </Select.Option>
        ))}
    </Select>
  );
};
